<template>
  <div class="news">
    <div class="banner">
      <img src="@/assets/news/banner.png" alt="" />
      <span>中飞科技新闻动态</span>
    </div>
    <div class="news_timeline">
      <light-timeline :items="timelineList">
        <template slot="tag" slot-scope="{ item }">
          {{ item.createTime }}
        </template>
        <template slot="content" slot-scope="{ item }">
          <div class="content">
            <div class="content_left">
              <img :src="item.image" alt="" />
            </div>
            <div class="content_right">
              <div class="content_right_top">
                <div class="content_right_title">
                  {{ item.title }}
                </div>
                <div class="content_right_text">
                  {{ item.synopsis }}
                </div>
              </div>

              <div class="content_right_bottom">
                <img
                  src="@/assets/news/read_button.png"
                  alt=""
                  @click="showDetails(item)"
                />
              </div>
            </div>
          </div>
        </template>
      </light-timeline>
    </div>
    <el-pagination
      layout="sizes,prev, pager, next,jumper"
      :total="total"
      :current-page.sync="listQuery.pageNum"
      :page-size.sync="listQuery.pageSize"
      :page-sizes="[3, 5, 7, 10]"
      @size-change="getNewsList"
      @current-change="getNewsList"
    >
    </el-pagination>
  </div>
</template>

<script>
import { newsList } from "@/api/web";
export default {
  name: "",
  data() {
    return {
      listQuery: {
        pageNum: 1,
        pageSize: 3,
      },
      timelineList: [],
      total: 0,
    };
  },
  created() {
    this.getNewsList();
  },
  methods: {
    showDetails(item) {
      this.$router.push(`/newsDetails/${item.newsId}`);
    },
    async getNewsList() {
      const { data } = await newsList(this.listQuery);
      this.timelineList = data.list;
      this.total = data.total;
    },
  },
};
</script>

<style scoped lang="scss">
.news {
  .banner {
    position: relative;
    img {
      width: calc(100% + 2px);
      margin-left: -1px;
    }
    span {
      position: absolute;
      top: 242px;
      left: 235px;
      font-size: 36px;
      color: #ffffff;
    }
  }
  .news_timeline {
    width: 1460px;

    margin: 0 auto;
    margin-top: 67px;
  }
}
.content {
  position: relative;
  display: flex;
  padding: 15px 30px 17px 19px;
  margin-left: 53px;
  width: 1100px;
  height: 278px;
  box-sizing: border-box;
  background-color: #eaeaea;
  &::before {
    position: absolute;
    content: "";
    left: -53px;
    top: 5px;
    width: 43px;
    height: 6px;
    background-color: #eaeaea;
  }
  .content_left {
    width: 491px;
    height: 235px;
    overflow: hidden;
    // transform: scale(0.8);
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .content_right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 30px;
    // transform: scale(0.8);

    .content_right_top {
      .content_right_title {
        width: 626px;
        font-size: 26px;
        color: #181818;
        line-height: 48px;
        word-break: break-all;
      }
      .content_right_text {
        width: 619px;
        margin-top: 20px;
        font-size: 16px;
        color: #181818;
        line-height: 30px;
        word-break: break-all;
      }
    }

    .content_right_bottom {
      text-align: right;
      height: 56px;
      img {
        height: 40px;
        cursor: pointer;
      }
    }
  }
}
::v-deep {
  .line-container {
    margin-left: 102px;
    .line-item {
      padding: 0;
      margin-bottom: 22px;
      .item-tag {
        top: -5px;
        left: -195px;
        width: 157px;
        height: 27px;
        line-height: 27px;
        background-color: #eaeaea;
        border-radius: 8px;
        color: #000;
      }
    }
    .item-circle {
      border-color: #f39800;
    }
    &::after {
      background-color: #eaeaea;
      width: 6px;
      left: 4.9rem;
      // top: 26px;
    }
  }
  .el-pagination {
    margin-top: 80px;
    margin-bottom: 60px;
    text-align: center;
    ul {
      li {
        width: 40px;
        height: 40px;
        line-height: 40px;
        margin-right: 5px;
        font-size: 16px;
      }
      .active {
        background: #e99315;
        color: #fff;
      }
    }
  }
  .el-pagination button {
    height: 40px;
    line-height: 40px;
  }
  .el-pagination button,
  .el-pagination span:not([class*="suffix"]) {
    height: 40px;
    line-height: 40px;
  }
}
</style>
